<template>
<div>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Händler</h4>
                            <p>Liste aller Händler</p>
                        </div>

                        <div class="card-tools">
                            <div class="row align-items-center">
                                <div class="col-md-12">
                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  

                                            <li class="nav-item mr-1 dropdown-submenu dropdown-hover">
                                                <!-- <button type="button" id="DealerCatalog" class="btn btn-sm btn-primary" @click="generateDealerCatalog()">
                                                    Händler-Katalog
                                                </button> -->

                                                <b-dropdown id="dropdown-left" size="sm" text="Händler-Katalog" variant="primary" class="mx-2">
                                                    <b-dropdown-item @click="generateDealerCatalog(0)">0% Händler-Rabatt</b-dropdown-item>
                                                    <b-dropdown-item @click="generateDealerCatalog(10)">10% Händler-Rabatt</b-dropdown-item>
                                                    <b-dropdown-item @click="generateDealerCatalog(15)">15% Händler-Rabatt</b-dropdown-item>
                                                    <b-dropdown-item @click="generateDealerCatalog(20)">20% Händler-Rabatt</b-dropdown-item>
                                                    <b-dropdown-item @click="generateDealerCatalog(25)">25% Händler-Rabatt</b-dropdown-item>
                                                    <b-dropdown-item @click="generateDealerCatalog(30)">30% Händler-Rabatt</b-dropdown-item>
                                                </b-dropdown>

                                                <!-- <button id="DealerCatalog" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="dropdown-item dropdown-toggle btn btn-sm btn-primary">Händler-Katalog</button>
                                                <ul aria-labelledby="DealerCatalog" class="border-0 shadow dropdown-menu ">
                                                    <li>
                                                        <a @click="generateDealerCatalog(10)" class="dropdown-item">10% Händler-Rabatt </a>
                                                    </li>
                                                    <li>
                                                        <a @click="generateDealerCatalog(15)" class="dropdown-item">15% Händler-Rabatt </a>
                                                    </li>
                                                    <li>
                                                        <a @click="generateDealerCatalog(20)" class="dropdown-item">20% Händler-Rabatt </a>
                                                    </li>
                                                    <li>
                                                        <a @click="generateDealerCatalog(25)" class="dropdown-item">25% Händler-Rabatt </a>
                                                    </li>
                                                    <li>
                                                        <a @click="generateDealerCatalog(30)" class="dropdown-item">30% Händler-Rabatt </a>
                                                    </li>
                                                </ul> -->
                                            </li>

                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm" style="margin-top: 0;">
                                                    <input type="text" class="form-control form-control-sm" placeholder="Suchen" @keyup.enter="loadDealers" :value="search" @input="$store.commit('dealers/changeSearch', $event.target.value)" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadDealers">
                                                        <i class="fas fa-fw fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-fw fa-times"></i>
                                                    </button>

                                                </div>
                                            </li>

                                            <li class="nav-item">
                                                <button type="button" class="btn mr-1  btn-sm btn-primary" @click="createModal" v-if="$auth.check('dealers.create')">
                                                    <i class="fas fa-fw fa-plus"></i>
                                                </button>
                                            </li>

                                            <li class="nav-item">
                                                <button type="button" class="btn btn-sm btn-default mr-1" @click="loadDealers">
                                                    <i class="fas fa-fw fa-sync"></i>
                                                </button>
                                            </li>

                                            <li class="nav-item d-none d-md-block">
                                                <button type="button" class="btn btn-sm btn-secondary mr-1" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                                            </li>
                                        </ul>                    
                                    </nav>
                                </div>
                            </div>
                            
                            
                            <!-- <a href="#" class="btn btn-primary pull-right">Create User</a> -->
                        </div>
                    </div>

                    <div class="card-body">
                        <template v-if="dealers.data.length > 0">
                        <div class="table-responsive">
                            <table class="table table-hover table-sm">
                                <thead>
                                    <th><input type="checkbox" v-model="selectAll" /></th>
                                    <th>
                                        <a href="#" @click.prevent="changeSort('number')">Händlernummer</a>
                                        <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'number' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col">
                                        <a href="#" @click.prevent="changeSort('name')">Name</a>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th scope="col">
                                        <a href="#" @click.prevent="changeSort('slug')">Slug</a>
                                        <span v-if="this.params.sort_field == 'slug' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                        <span v-if="this.params.sort_field == 'slug' && this.params.sort_direction == 'desc'" >&darr;</span>
                                    </th>
                                    <th style="width: 140px;">Aktionen</th>                 
                                </thead>
                                <tbody>
                                    <tr v-for="(dealer) in dealers.data" :key="dealer.id">
                                        <td><input type="checkbox" :value="dealer.id" v-model="selectedDealers"/></td>
                                        <td><span class="badge badge-pill badge-dark">{{ dealer.number }}</span></td>
                                        <td>{{ dealer.name }}</td>
                                        <td>{{ dealer.slug }}</td>                                      
                                        <td>
                                            <router-link class="mr-1 btn btn-info btn-xs" :to="{name: 'dealer-orders.create', params: {id: dealer.id}}" v-if="$auth.check('dealer_orders.create')"><i class="fas fa-fw fa-truck"></i></router-link>
                                            <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'dealers.show', params: {id: dealer.id}}" v-if="$auth.check('dealers.show')"><i class="fas fa-fw fa-eye"></i></router-link>
                                            <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'dealers.details', params: {id: dealer.id}}" v-if="$auth.check('dealers.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                            <b-button size="xs" @click="deleteDealer(dealer.id)" variant="danger" v-if="$auth.check('dealers.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        </template>

                        <template v-else>
                            <h5>Keine Händler gefunden</h5>
                        </template>
                    </div>

                    <div class="card-footer">
                        <template v-if="dealers.data.length > 0">
                            <div class="row align-items-center">
                                <div class="col-md-9">
                                    <pagination class="float-left" :data="dealers" @pagination-change-page="loadDealers" :limit="3"></pagination>
                                </div>
                                <div class="col-md-2">
                                    <span class="float-right">Anzeige Eintrag {{ dealers.meta.from }} - {{ dealers.meta.to }} von {{ dealers.meta.total }}</span>
                                </div>
                                <div class="col-md-1">
                                    <select class="float-right form-control form-control-sm" v-model="params.per_page">
                                        <option value="25">25 Händler</option>
                                        <option value="50">50 Händler</option>
                                        <option value="75">75 Händler</option>
                                        <option value="100">100 Händler</option>
                                        <option value="125">125 Händler</option>
                                        <option value="150">150 Händler</option>
                                    </select>
                                </div>
                            </div>                        
                        </template>
                    </div>
                </div>
            </div>
        </div>

        <b-modal id="dealerModal" title="Neuen Händler anlegen" ok-only ok-variant="danger" ok-title="Speichern" size="xl" @ok="handleOk">
                <form ref="form" @submit.stop.prevent="handleSubmit" enctype="multipart/form-data">

                    <div class="row">
                        <div class="col-md-12">

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label for="company" class="control-label">Firma*</label>
                                        <select v-model="form.company_id" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('company')}">
                                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                                        </select>
                                        <has-error :form="form" field="company"></has-error>
                                    </div>
                                </div>
                            </div>
                            

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="name" class="control-label">Name*</label>
                                        <input v-model="form.name" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('name')}">
                                        <has-error :form="form" field="name"></has-error>
                                    </div>

                                    <div class="col-md-4">
                                        <label for="number" class="control-label">Händlernummer*</label>
                                        <input v-model="form.number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('number')}">
                                        <has-error :form="form" field="number"></has-error>
                                    </div>

                                    <div class="col-md-4">
                                        <label for="discount" class="control-label">Rabatt(%)*</label>
                                        <input v-model="form.discount" type="number" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('discount')}">
                                        <has-error :form="form" field="discount"></has-error>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="active" class="control-label">Online anzeigen?*</label>
                                <select v-model="form.active" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('active')}">
                                    <option value="1">Ja</option>
                                    <option value="0">Nein</option>
                                </select>
                                <has-error :form="form" field="active"></has-error>
                            </div>
                            

                            <div class="form-group">
                                <label for="description" class="control-label">Beschreibung</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.description" :class="{'is-invalid': form.errors.has('description')}"></ckeditor>
                                <has-error :form="form" field="description"></has-error>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-10">
                                        <div class="form-group">
                                            <label for="street" class="control-label">Straße*</label>
                                            <input v-model="form.street" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('street')}">
                                            <has-error :form="form" field="street"></has-error>
                                        </div>
                                    </div>

                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="house_number" class="control-label">Nr.*</label>
                                            <input v-model="form.house_number" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('house_number')}">
                                            <has-error :form="form" field="house_number"></has-error>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="zipcode" class="control-label">PLZ*</label>
                                        <input v-model="form.zipcode" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('zipcode')}">
                                        <has-error :form="form" field="zipcode"></has-error>
                                    </div>

                                    <div class="col-md-8">
                                        <label for="city" class="control-label">Ort*</label>
                                        <input v-model="form.city" type="text" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('city')}">
                                        <has-error :form="form" field="city"></has-error>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label for="logo" class="control-label">Logo</label>
                                <!-- <input v-model="form.logo" type="file" class="form-control form-control-sm" :class="{'is-invalid': form.errors.has('logo')}"> -->
                                <b-form-file id="logo" size="sm" v-model="form.logo" :class="{'is-invalid': form.errors.has('logo')}" />
                                <has-error :form="form" field="logo"></has-error>
                            </div>
                            <div class="form-group">
                                <label for="notice" class="control-label">Notizen</label>
                                <ckeditor :editor="editor" :config="editorConfig" v-model="form.notice" :class="{'is-invalid': form.errors.has('notice')}"></ckeditor>
                                <has-error :form="form" field="notice"></has-error>
                            </div>
                        </div>
                    </div>
                </form>
            </b-modal>

    </b-container>

    <aside class="control-sidebar control-sidebar-light">
        <div class="p-3">
            <h3>Filteroptionen</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Sodataste:</label>
                        <select v-model="params.sodataste" class="form-control form-control-sm">
                            <option :value="''">Beide</option>
                            <option :value="0">Nur Hofladen</option>
                            <option :value="1">Nur SodaTaste</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <div class="form-group">
                        <button type="button" class="btn btn-sm btn-secondary" @click="resetFilter">Filter zurücksetzen</button>
                    </div>
                </div>
            </div>

        </div>  
    </aside>
</div>
</template>

<script>

import ClassicEditor from './../../assets/packages/ckeditor5/src/ckeditor';
import {mapState} from 'vuex';

export default {
    name: "Dealers",
    title: "Händler",
    data() {
        return {
            form: new window.UploadForm({
                id: "",
                name: "",
                number: "",
                discount: "",
                description: "",
                street: "",
                house_number: "",
                zipcode: "",
                city: "",
                logo: [],
                company_id: '',
                active: '',
                notice: "", 
            }),
            dealers: {
                data: [],
            },
            companies: [],
            selectedDealers: [],
            editor: ClassicEditor,
            editorConfig: {
                simpleUpload: {
                    uploadUrl: process.env.VUE_APP_API_URL + 'images/upload',
                    withCredentials: true,
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': 'Bearer '+ this.$auth.token()
                    }
                }
            },
        }
    },

    watch: {
        params: {
            handler () {
                this.loadDealers();
            },
            deep: true, 
        },
    },

    computed: {
        selectAll: {
            get: function () {
                return this.dealers.data ? this.selectedDealers.length == this.dealers.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.dealers.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedDealers = selected;
            }
        },
        ...mapState('dealers', ['params', 'search', 'page'])
    },

    methods: {
        resetFilter(){
            this.params.sodataste = '';
            this.params.per_page = 25;
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
    
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },

        generateDealerCatalog(discount){
            this.axios
            .post("/dealers/catalog", {
                'email': this.$auth.user().email,
                'discount': discount,
            }, {responseType: 'arraybuffer'})
            .then((response) => {
                this.downloadFile(response,discount)
                this.$swal({
                    icon: "success",
                    title: "Der Händler-Katalog wird Dir per E-Mail gesendet",
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000,
                    timerProgressBar: true,
                });
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufenn",
                });
                this.$Progress.fail();
            });
        }, 

        downloadFile(response, discount){
            var newBlob = new Blob([response.data], {type: 'application/pdf'})

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(newBlob)
            return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            var link = document.createElement('a')
            link.href = data
            link.download = 'Katalog-' + discount + '.pdf'
            link.click()
            setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data)
            }, 100)
        },
        resetSearch(){
            this.$store.commit('dealers/changeSearch', '');
            this.loadDealers();
        },

        deleteSelectedDealers(){
            this.$swal({
                    title: "Möchtest du die selektierten Händler wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Ja, LÖSCHEN!!!`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.axios
                            .post("/dealers/deleteMany", {data: this.selectedDealers})
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Händler erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.selectedDistributors = [];
                                this.loadDealers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        selectImage(event){
            this.form.logo = event.target.files[0];
            console.log(event.target.files[0]);
            console.log(this.form.logo);

        },
        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault()
            this.handleSubmit()
        },

        handleSubmit() {
            this.createDealer();
        },

        createModal() {
            this.form.clear();
            this.form.reset();
            this.form.company_id = this.companies[0].id;
            this.$bvModal.show("dealerModal");
        },

        deleteDealer(id) {
            this.$swal({
                    title: "Möchtest du den Händler wirklich löschen?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: `Löschen`,
                    denyButtonText: `Abbrechen`,
                })
                .then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        //Send request to the Server
                        this.form
                            .delete("/dealers/" + id)
                            .then(() => {
                                this.$swal({
                                    icon: "success",
                                    title: "Händler erfolgreich gelöscht!",
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 2000,
                                    timerProgressBar: true,
                                    });
                                this.loadDealers();
                            })
                            .catch(() => {
                                this.$swal({
                                    icon: "error",
                                    title: "Oops...",
                                    text: "Da ist etwas schief gelaufen",
                                });
                            });
                    }
                });
        },

        createDealer() {
            this.$Progress.start();
            this.form
                .post("/dealers")
                .then(() => {
                    this.$bvModal.hide("dealerModal");
                    this.$swal({
                        icon: "success",
                        title: "Händler wurde angelegt",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                    });
                    this.loadDealers();
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                    this.$Progress.fail();
                });
        },

        loadCompanies() {
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },


        loadDealers(page) {
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/dealers",{
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.dealers = response.data;
                    this.$store.commit('dealers/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        }
    },


    created() {
        this.loadDealers();
        this.loadCompanies();
    },
    mounted() {
        console.log(this.$auth.user());
    }



}
</script>

<style>

</style>